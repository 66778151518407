.previous, .next, .submit {
  min-width: 120px;
}

.avatar-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.avatar-wrapper img {
  width: 100%;
  height: 100%;
}

.avatar-backdrop {
  position: absolute;
  top: 100px;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-candidate {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.avatar-candidate img {
  width: inherit;
  height: inherit;
}